export const DEFAULT_ACTION = 'app/Structure/DEFAULT_ACTION';

export const OPEN_NOTIF_ACTION = 'app/Structure/OPEN_NOTIF_ACTION';
export const OPEN_DIALOG_ACTION = 'app/Structure/OPEN_DIALOG_ACTION';
export const OPEN_MENU_ACTION = 'app/Structure/OPEN_MENU_ACTION';

export const GET_PROFILE_DATA_REQUEST_ACTION =
  'app/Structure/GET_PROFILE_DATA_REQUEST_ACTION';

export const GET_PROFILE_DATA_RESPONSE_ACTION =
  'app/Structure/GET_PROFILE_DATA_RESPONSE_ACTION';

export const TOGGLE_INDETERMINATE_PROGRESS_MOTION_ACTION =
  'app/Structure/TOGGLE_INDETERMINATE_PROGRESS_MOTION_ACTION';
